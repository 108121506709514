import React from "react";
import Back from "../components/backbutton";
import Layout from "../components/layout";
import RestaurantList from "../components/restaurants";
import KaffeeList from "../components/kaffeeundkuchen";
function RestaurantsPage() {
  return (
    <Layout>
   <Back></Back>
<RestaurantList></RestaurantList>
<KaffeeList></KaffeeList>

    </Layout>
  );
}

export default RestaurantsPage;
